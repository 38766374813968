import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useInView } from 'react-intersection-observer';
import styles from '../../styles/components/home/Team.module.css';
import { FaLinkedin } from "react-icons/fa";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";
import { delay } from 'framer-motion';

const teamMembers = [
  { name: 'Swaroop', role: 'Founder & CEO', color: 'red', img: '/assets/images/avatars/swaroop.png', url: 'https://www.linkedin.com/in/swaroopjayaram' },
  { name: 'Bhavana', role: 'Operations Head', color: 'skyblue', img: '/assets/images/avatars/bhavana.png', url: 'https://www.linkedin.com/in/bhavana-gopalaiah' },
  { name: 'Kavya', role: 'Sales & Operations', color: 'maroon', img: '/assets/images/avatars/kavya.png' },
  { name: 'Ranjith', role: 'Cloud Engineer', color: 'pink', img: '/assets/images/avatars/ranjith.png' },
  { name: 'Rakesh', role: 'Data Engineer', color: 'purple', img: '/assets/images/avatars/rakesh.png' },
  { name: 'Sundeep', role: 'Full Stack Developer', color: 'darkred', img: '/assets/images/avatars/sundeep.png', url: 'https://www.linkedin.com/in/sundeep8967' },
  { name: 'Shahina', role: 'Full Stack Developer', color: 'violet', img: '/assets/images/avatars/shahina.png' },
  { name: 'Manoj', role: 'Full Stack Developer', color: 'lightgreen', img: '/assets/images/avatars/manoj.png' },
  { name: 'Megha', role: 'Project Manager', color: '#0F0F0F', img: '/assets/images/avatars/megha.png' },
  { name: 'Punith', role: 'Finance Engineer', color: 'orange', img: '/assets/images/avatars/punith.png' },
  { name: 'Vishal', role: 'UI / UX Designer', color: 'blue', img: '/assets/images/avatars/vishal.png', url: 'https://www.linkedin.com/in/vishal-h-m-067358224' },
  { name: 'Soniya', role: 'UI / UX Designer', color: '#228B22', img: '/assets/images/avatars/soniya.png' },
  { name: 'Gajendra', role: 'Flutter Developer', color: '#228B22', img: '/assets/images/avatars/gajendra.png' },
  { name: 'Vajra', role: 'UI / UX Designer', color: '#228B22', img: '/assets/images/avatars/vajra.png' },
  { name: 'Ashwin', role: 'Backend Developer', color: '#228B22', img: '/assets/images/avatars/ashwin.png' }
];

const Team = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef(null);

  const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.2 });

  useEffect(() => {
    if (sliderRef.current) {
      if (inView) {
        sliderRef.current.slickPlay();
      } else {
        sliderRef.current.slickPause();
      }
    }
  }, [inView]);

  useEffect(() => {
    if (slideIndex === teamMembers.length - 5) {
      setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(0);
        }
      }, 2500);
    }
  }, [slideIndex]);

  const NextArrow = ({ onClick }) => (
    <button
      className={`${styles.arrow} ${styles.next} ${slideIndex === teamMembers.length - 5 ? styles.disabled : ''}`}
      onClick={onClick}
      aria-label="Next Slide"
      disabled={slideIndex === teamMembers.length - 5}
    >
      <BiChevronRight />
    </button>
  );

  const PrevArrow = ({ onClick }) => (
    <button
      className={`${styles.arrow} ${styles.prev} ${slideIndex === 0 ? styles.disabled : ''}`}
      onClick={onClick}
      aria-label="Previous Slide"
      disabled={slideIndex === 0}
    >
      <BiChevronLeft />
    </button>
  );

  const settings = {
    dots: true,
    infinite: false,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    autoplaySpeed: 5000,
    centerPadding: "48px",
    pauseOnHover: true,
    swipeToSlide: true,
    adaptiveHeight: true,
    cssEase: "ease-in-out",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (oldIndex, newIndex) => setSlideIndex(newIndex),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div ref={ref} className={styles.container}>
      <h2 className={styles.heading}>Meet The Team</h2>
      <div className={styles.sliderWrapper}>
        <Slider ref={sliderRef} {...settings} className={styles.slider}>
          {teamMembers.map((member, index) => (
            <div key={index} className={styles.card}>
              <img src={member.img} className={styles.avatar} alt={member.name} />
              <div className={styles.infoWrapper}>
                <span className={styles.name}>{member.name}</span>
                <span className={styles.role}>{member.role}</span>
                {member.url && (
                  <a href={member.url} className={styles.linkedInIcon} aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin />
                  </a>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Team;
