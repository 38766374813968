import React, { useEffect } from "react";
import styles from "../../styles/components/careers/CurrentOpenings.module.css";
import { FaSuitcase, FaRegCalendarAlt } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { IoShareSocialOutline } from "react-icons/io5";
import { RiVerifiedBadgeLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { careersData } from "../../utils/constants";
import { toast, ToastContainer } from "react-toastify";

const jobsData = careersData.map(job => ({
    ...job,
    status: job.applicants >= job.slots ? "Closed" : "Open",
    progress: Math.min((job.applicants / job.slots) * 100, 100),
})).slice(0, 3);

const getProgressColor = (progress) => {
    if (progress >= 80) return "#E25A5A";
    if (progress >= 50) return "#f4a261";
    return "#2a9d8f";
};

const CurrentOpenings = () => { 
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const copyToClipboard = (job) => {
        navigator.clipboard.writeText(`https://joms.in/careers/openings/job-details/${job.id}`)
          .then(() => toast.success("Link copied to clipboard!"))
          .catch(err => toast.error("Failed to copy:", err));
      };

    return (
        <>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        <div id="current-openings" className={styles.container}>
            <h2 className={styles.heading}>Current Openings</h2>

            <div className={styles.jobGrid}>
                {jobsData.length > 0 ? jobsData.map((job, index) => (
                    <div key={index} className={styles.jobCard}>
                        <div className={styles.shareIcon} onClick={()=> copyToClipboard(job)}>
                            <IoShareSocialOutline />
                        </div>
                        <div className={styles.jobCardHeader}>
                            <h3 className={styles.jobTitle}>{job.title}</h3>
                        </div>
                        <div className={styles.jobDetails}>
                            <span><FaSuitcase /> {job.details.type}</span>
                            <span><FiMapPin /> {job.details.mode}</span>
                            {job.details.jobType === "Internship" && <span><FaRegCalendarAlt /> {job.details.duration}</span>}
                            {job.details.jobType ==="Full Time" && <span><RiVerifiedBadgeLine /> {job.details?.experience.slice(0,1)}+ Years</span> }
                        </div>
                        <div className={styles.roleLabel}>Role:</div>
                        <p className={styles.roleDescription}>
                            {job.shortDescription}
                        </p>
                        <div className={styles.progressContainer}>
                            <div className={styles.progressBar}>
                                <div
                                    className={styles.progressFill}
                                    style={{
                                        width: `${job.progress}%`,
                                        backgroundColor: getProgressColor(job.progress),
                                    }}
                                />
                            </div>
                            <span style={{ color: getProgressColor(job.progress) }}>
                                {job.progress >= 80 ? "Closes Soon" : job.progress >= 50 ? "Filling Fast" : "Open"}
                            </span>
                        </div>
                        <button
                            className={styles.applyButton}
                            onClick={()=> navigate(`/careers/openings/job-details/${job.id}`, { state: { job } })}
                            disabled={job.status === "Closed"}
                        >
                            {job.status === "Closed" ? "Closed" : "Apply"}
                        </button>
                    </div>
                )) : (
                    <p className={styles.noResults}>No jobs found</p>
                )}
            </div>
            <div className={styles.viewMoreContainer}>
                <button className={styles.viewMoreButton} onClick={() => navigate("/careers/openings")}>View More</button>
            </div>
        </div>
        </>
    );
};

export default CurrentOpenings;
