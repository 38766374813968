import React from 'react';
import styles from "../styles/pages/CookiePolicy.module.css";

const CookiePolicy = () => {
    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <h1>JOMS Commerce & Technologies Private Limited – Cookie Policy</h1>
                <p>Last Updated On: January 28, 2025</p>
            </header>

            <section className={styles.section}>
                <p>At JOMS Commerce & Technologies Private Limited ("Company," "we," "our," or "us"), we use
                    cookies and similar technologies to enhance your experience on our website. This Cookie Policy
                    explains what cookies are, how we use them, and your choices regarding their use.</p>
            </section>

            <section className={styles.section}>
                <h2>1. What Are Cookies?</h2>
                <p>
                    Cookies are small text files stored on your device (computer, smartphone, or other devices) when
                    you visit a website. They help websites recognize your device, store your preferences, and
                    improve your browsing experience.
                </p>
                <p>Cookies can be classified as:</p>
                <ul>
                    <li><strong>Session Cookies:</strong> Temporary cookies that expire when you close your browser.</li>
                    <li><strong>Persistent Cookies:</strong> Cookies that remain on your device until they expire or are deleted.</li>
                    <li><strong>First-Party Cookies:</strong> Set by the website you are visiting.</li>
                    <li><strong>Third-Party Cookies:</strong> Set by third-party services integrated into the website.</li>
                </ul>
            </section>

            <section className={styles.section}>
                <h2>2. Types of Cookies We Use</h2>
                <ul>
                    <li><strong>Essential Cookies:</strong> Necessary for the operation of our website, such as enabling you to log in.</li>
                    <li><strong>Performance Cookies:</strong> Collect information about how users interact with our website to
                        help us improve its performance.</li>
                    <li><strong>Functional Cookies:</strong> Allow us to remember your preferences and provide a more
                        personalized experience.</li>
                    <li><strong>Analytics Cookies:</strong> Help us understand website traffic, user behavior, and other metrics using tools like Google Analytics.</li>
                    <li><strong>Advertising Cookies:</strong> Enable us to deliver targeted advertisements based on your
                        interests and track ad performance.</li>
                </ul>
            </section>

            <section className={styles.section}>
                <h2>3. Why We Use Cookies</h2>
                <p>We use cookies to:</p>
                <ul>
                    <li>Improve website functionality and user experience.</li>
                    <li>Analyze website performance and usage patterns.</li>
                    <li>Deliver personalized content and recommendations.</li>
                    <li>Manage security and prevent fraudulent activity.</li>
                    <li>Display relevant advertisements and measure their effectiveness.</li>
                </ul>
            </section>

            <section className={styles.section}>
                <h2>4. Third-Party Cookies</h2>
                <p>Our website may include third-party cookies from services like:</p>
                <ul>
                    <li><strong>Google Analytics:</strong> To analyze website traffic and usage.</li>
                    <li><strong>Social Media Platforms:</strong> To enable sharing and interaction with content.</li>
                    <li><strong>Advertising Networks:</strong> To display relevant ads.</li>
                </ul>
                <p>These third parties may collect and use information in accordance with their own privacy
                    policies.</p>
            </section>

            <section className={styles.section}>
                <h2>5. Managing Cookies</h2>
                <ul>
                    <li><strong>Browser Settings:</strong> You can configure your browser to refuse or delete cookies.</li>
                    <li><strong>Opt-Out Options:</strong> Some third-party services, such as Google Analytics, provide tools to
                        opt out of data collection.</li>
                    <li><strong>Cookie Banner:</strong> When you first visit our website, you can manage your cookie
                    preferences through the cookie consent banner.</li>
                </ul>
                <p>Please note that disabling cookies may affect the functionality of our website and limit your
                access to certain features.</p>
            </section>

            <section className={styles.section}>
                <h2>6. Your Choices</h2>
                <p>You can:</p>
                <ul>
                    <li>Accept or decline cookies via the cookie consent banner.</li>
                    <li>Adjust your browser settings to block or delete cookies.</li>
                    <li>Use third-party opt-out tools to manage advertising preferences.</li>
                </ul>
            </section>

            <section className={styles.section}>
                <h2>7. Updates to This Policy</h2>
                <p>We may update this <a href='/cookiepolicy' className={styles.link}>Cookie Policy</a> from time to time to reflect changes in technology, laws, or
                business practices. Any updates will be posted on this page with the revised effective date.</p>
            </section>

            <section className={styles.section}>
                <h2>8. Contact Information</h2>
                <p>If you have questions or concerns about our use of cookies, please contact us at:</p>
                <p>
                    <strong>JOMS Commerce & Technologies Private Limited</strong><br />
                    No-592/2/635, NGEF Layout, Nagarbhavi,<br />
                    Bangalore North, Bangalore-560072, Karnataka, India<br />
                    Email: <a href="mailto:hello@joms.in" className={styles.link}>hello@joms.in</a>
                </p>
            </section>
        </div>
    );
};

export default CookiePolicy;