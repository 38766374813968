import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../../styles/components/home/Newsletter.module.css";
import { API_BASE_URL } from "../../utils/constants";

const Newsletter = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    stayTuned: false,
    userConsent: false,
  });

  const [loading, setLoading] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  useEffect(() => {
    setButtonEnabled(formData.name.trim() && formData.email.trim());
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.userConsent) {
      toast.warning("You must provide consent to subscribe.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/joms-subscription/subscribe`, formData);
      if (response.data.success) {
        toast.success("Subscription successful!");
        setFormData({ name: "", email: "", stayTuned: false, userConsent: false });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className={styles.container}>
        <h2 className={styles.heading}>Stay Ahead And Be The First To Know</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <input
            name="name"
            type="text"
            value={formData.name}
            placeholder="What's Your Name?"
            onChange={handleChange}
            className={styles.input}
          />
          <input
            name="email"
            type="email"
            value={formData.email}
            placeholder="What's Your Email?"
            onChange={handleChange}
            className={styles.input}
          />
          <div className={styles.checkboxContainer}>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                name="userConsent"
                checked={formData.userConsent}
                className={styles.checkbox}
                onChange={handleChange}
              />
              I agree to the <a href="/termsofservice" className={styles.link} >Terms of Service</a>
            </label>
          </div>
          <button type="submit" disabled={!buttonEnabled || loading} className={styles.subscribeButton}>
            {loading ? "Subscribing..." : "Subscribe"}
          </button>
        </form>
      </div>
    </>
  );
};

export default Newsletter;