import React, { useEffect } from 'react'
import Hero from '../components/internship/Hero'
import WhyJOMS from '../components/internship/WhyJOMS'
import CareerExplorer from '../components/internship/CareerExplorer'
import Qualification from '../components/internship/Qualification'
import WhyWithUs from '../components/internship/WhyWithUs'
import HowToApply from '../components/internship/HowToApply'
import Requirements from '../components/internship/Requirements'
import Footer from '../components/home/Footer'
import JobSubscription from '../components/careers/JobSubscription'

const Internship = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Hero />
            <WhyJOMS />
            <CareerExplorer />
            <Qualification />
            <WhyWithUs />
            <HowToApply />
            <Requirements />
            <JobSubscription />
            <hr/>
            <Footer />
        </>
    )
}

export default Internship