import React from 'react';
import styles from '../../styles/components/internship/Requirements.module.css';

const Requirements = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>
        Documentation Requirements
        {/* , <span>if selected for Internship.</span> */}
      </h2>
      <ul className={styles.list}>
        <li>A copy of the student's ID card.</li>
        <li>
          A copy of the student's government ID and address proof such as Aadhar card, Voter ID, Driving License, or Passport.
        </li>
        <li>
          No Objection Certificate (NOC) from the college on its letterhead with the student's full name, field of study, year of study, admission number, and contact details of college faculty or management.
        </li>
      </ul>
    </div>
  );
};

export default Requirements;
