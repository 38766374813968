import { useEffect } from "react";

const useGoogleAnalytics = (measurementId) => {
  useEffect(() => {
    // Load Google Analytics script dynamically
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){ window.dataLayer.push(arguments); }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', measurementId);
  }, [measurementId]);
};

export default useGoogleAnalytics;
