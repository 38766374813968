import React from 'react';
import styles from '../../styles/components/home/Products.module.css';
import { FaApple, FaGooglePlay } from 'react-icons/fa';

const Products = () => {
  return (
    <div id="products" className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.heading}>Our Products</h2>

        <div className={styles.imageContainer}>
          <img 
            src="/assets/images/home/launching-soon.png" 
            alt="Launching Soon Product" 
            className={styles.image} 
          />
          <div className={styles.overlay}>
            <h3 className={styles.launchText}>Launching Soon</h3>
            <div className={styles.icons}>
              <a href="https://www.apple.com/app-store/" aria-label="Apple App Store">
                <FaApple className={styles.storeIcon} />
              </a>
              <a href="https://play.google.com/store" aria-label="Google Play Store">
                <FaGooglePlay className={styles.storeIcon} />
              </a>
            </div>
            <p className={styles.stayTuned}>Stay Tuned</p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Products;
