import React, { useEffect, useState } from "react";
import styles from "../styles/pages/Openings.module.css";
import { FaSuitcase, FaRegCalendarAlt } from "react-icons/fa";
import { FiSearch, FiMapPin } from "react-icons/fi";
import { IoShareSocialOutline } from "react-icons/io5";
import { RiVerifiedBadgeLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { careersData } from "../utils/constants";
import { motion, AnimatePresence } from "framer-motion";
import { toast, ToastContainer } from "react-toastify";
import { IoIosArrowRoundBack } from "react-icons/io";

const jobsData = careersData.map(job => ({
  ...job,
  status: job.applicants >= job.slots ? "Closed" : "Open",
  progress: Math.min((job.applicants / job.slots) * 100, 100),
}));

const getProgressColor = (progress) => {
  if (progress >= 80) return "#E25A5A";
  if (progress >= 50) return "#f4a261";
  return "#2a9d8f";
};

const Openings = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredJobs, setFilteredJobs] = useState(jobsData);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState({ jobType: "", mode: "", experience: "", duration: "" });

  const navigate = useNavigate();

  const copyToClipboard = (job) => {
    navigator.clipboard.writeText(`https://joms.in/careers/openings/job-details/${job.id}`)
      .then(() => toast.warning("Link copied to clipboard!"))
      .catch(err => toast.error("Failed to copy:", err));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    filterJobs(term, filters);
  };

  const filterJobs = (search, filterValues) => {
    let filtered = jobsData.filter(job =>
      job.title.toLowerCase().includes(search.toLowerCase())
    );
  
    if (filterValues.jobType) filtered = filtered.filter(job => job.details.jobType === filterValues.jobType);
    if (filterValues.mode) filtered = filtered.filter(job => job.details.mode.toLowerCase() === filterValues.mode.toLowerCase());
    if (filterValues.experience) filtered = filtered.filter(job => job.details.experience.toLowerCase() === filterValues.experience.toLowerCase());
    if (filterValues.duration) filtered = filtered.filter(job => job.details.duration.toLowerCase() === filterValues.duration.toLowerCase());
  
    setFilteredJobs(filtered);
  };
  

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleApply = (job) => {
    navigate(`/careers/openings/job-details/${job.id}`, { state: { job } });
  };

  useEffect(() => {
    filterJobs(searchTerm, filters);
    // eslint-disable-next-line
  }, [searchTerm, filters.jobType, filters.mode, filters.experience, filters.duration]);
  

  return (
    <>
    <ToastContainer  position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    <div className={styles.careersContainer}>
      <button className={styles.backButton} onClick={() => navigate(-1)}>
            <IoIosArrowRoundBack /> <span>Careers</span>
      </button>
      <header className={styles.header}>
        <h2>Current Openings</h2>
      </header>

      {/* <div className={styles.tabContainer}>
        {['Full Time', 'Internship'].map(tab => (
          <button
            key={tab}
            className={`${styles.tabButton} ${selectedTab === tab ? styles.activeTab : ""}`}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div> */}

      <div className={styles.searchFilter}>
        <div className={styles.searchBarContainer}>
          <FiSearch className={styles.searchIcon} />
          <input
            type="text"
            placeholder="Search"
            className={styles.searchBar}
            value={searchTerm}
            onChange={handleSearch}
          />
          <div className={styles.filterDropdown}>
            <button className={styles.filterButton} onClick={() => setIsFilterOpen(!isFilterOpen)}>
              <img src="/assets/images/filter_icon.svg" alt="Filter" />
            </button>
            <AnimatePresence>
              {isFilterOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10}}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className={styles.filterModal}
                >
                  <div className={styles.filterHeader}>
                    <h3>Filter Options</h3>
                    <button className={styles.closeButton} onClick={() => setIsFilterOpen(false)}>×</button>
                  </div>
                  <div className={styles.filterContent}>
                    <label>Job Type:
                      <select name="jobType" value={filters.jobType} onChange={handleFilterChange}>
                        {/* <option value="">All</option> */}
                        <option value="Full Time">Full Time</option>
                        <option value="Internship">Internship</option>
                      </select>
                    </label>
                    <label>Type:
                      <select name="mode" value={filters.mode} onChange={handleFilterChange}>
                        <option value="">All</option>
                        <option value="Hybrid">Hybrid</option>
                        <option value="Remote">Remote</option>
                        <option value="On-Site">On-Site</option>
                      </select>
                    </label>
                    {/* {filters.jobType === "Full Time" && <label>Experience:
                      <select name="experience" value={filters.experience} onChange={handleFilterChange}>
                        <option value="">All</option>
                        <option value="0-2 Years">0-2 Years</option>
                        <option value="3-6 Years">3-6 Years</option>
                        <option value="6+ Years">6+ Years</option>
                      </select>
                    </label>}
                    {filters.jobType !== "Full Time" && <label>Duration:
                      <select name="duration" value={filters.duration} onChange={handleFilterChange}>
                        <option value="">All</option>
                        <option value="3 Months">3 Months</option>
                        <option value="3-6 Months">3-6 Months</option>
                        <option value="More than 6 Months">More than 6 Months</option>
                      </select>
                    </label>} */}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>

      <motion.div layout className={styles.jobGrid}>
        {filteredJobs.map((job, index) => (
          <motion.div layout key={index} className={styles.jobCard}>
            <div className={styles.shareIcon} onClick={()=> copyToClipboard(job)}><IoShareSocialOutline /></div>
            <div className={styles.jobCardHeader}>
              {job.details.jobType=== "Full Time" && <h3 className={styles.jobTitle}>{job.title}</h3>}
              {job.details.jobType=== "Internship" && <h3 className={styles.jobTitle}>{job.title.split(" - ")[0]} - <br/>{job.title.split(" - ")[1]}</h3>}
            </div>
            <div className={styles.jobDetails}>
              <span><FaSuitcase /> {job.details.type}</span>
              <span><FiMapPin /> {job.details.mode}</span>
             {job.details.jobType ==='Internship' && <span><FaRegCalendarAlt /> {job.details?.duration}</span>}
            {job.details.jobType === 'Full Time' && <span><RiVerifiedBadgeLine /> {job.details?.experience.slice(0,1)}+ Years</span> }
            </div>
            <div className={styles.roleLabel}>Role:</div>
            <p className={styles.roleDescription}>{job.shortDescription}</p>
            <div className={styles.progressContainer}>
              <div className={styles.progressBar}>
                <div
                  className={styles.progressFill}
                  style={{ width: `${job.progress}%`, backgroundColor: getProgressColor(job.progress) }}
                />
              </div>
              <span style={{ color: getProgressColor(job.progress), fontSize: 12 }}>
                {job.progress === 100 ? "Closed" : job.progress >= 80 && job.progress < 100 ? "Closes Soon" : job.progress >= 50 ? "Filling Fast" : "Open"}
              </span>
            </div>
            <button className={styles.applyButton} onClick={() => handleApply(job)} disabled={job.status === "Closed"}>
              {job.status === "Closed" ? "Closed" : "Apply"}
            </button>
          </motion.div>
        ))}
      </motion.div>
    </div>
    </>
  );
};

export default Openings;
