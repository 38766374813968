import React from "react";
import styles from "../../styles/components/home/Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <p>© 2024 JOMS Commerce & Technologies Private Limited. All Rights Reserved.</p>
        <ul className={styles.links}>
          <li>
            <a href="/privacypolicy">Privacy Policy</a>
          </li>
          <li>
            <a href="/termsofservice">Terms of Service</a>
          </li>
          <li>
            <a href="/disclaimer">Disclaimer</a>
          </li>
          <li>
            <a href="/cookiepolicy">Cookie Policy</a>
          </li>
        </ul>
        
      </div>
    </footer>
  );
};

export default Footer;
