import React from 'react';
import styles from "../styles/pages/Disclaimer.module.css";

const Disclaimer = () => {
    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <h1>JOMS Commerce & Technologies Private Limited – Disclaimer</h1>
                <p>Last Updated On: January 28, 2025</p>
            </header>
            <section className={styles.section}>
                <p>The information provided on the JOMS Commerce & Technologies Private Limited website
                    ("Website") is for general informational purposes only. While we strive to ensure that the
                    information is accurate and up-to-date, we make no representations or warranties of any kind,
                    express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or
                    completeness of any information on the Website.
                </p>
            </section>
            <section className={styles.section}>
                <h2>1. No Professional Advice</h2>
                <p>
                    The Website does not offer professional advice. Any reliance you
                    place on the information provided is strictly at your own risk. For specific advice regarding your
                    situation, consult a qualified professional.
                </p>
            </section>

            <section className={styles.section}>
                <h2>2. Limitation of Liability</h2>
                <p>
                    Under no circumstances shall JOMS Commerce & Technologies
                    Private Limited be liable for any direct, indirect, incidental, consequential, or punitive damages
                    arising out of or in connection with your use of the Website, including but not limited to errors or
                    omissions in the content.
                </p>
            </section>

            <section className={styles.section}>
                <h2>3. External Links</h2>
                <p>
                    The Website may contain links to external websites. These links are provided
                    for your convenience, and we are not responsible for the content, policies, or practices of these
                    external sites.
                </p>
            </section>

            <section className={styles.section}>
                <h2>4. Endorsements</h2>
                <p>
                    Any reference to third-party products, services, or information does not constitute an endorsement
                    or recommendation by JOMS Commerce & Technologies Private Limited.
                </p>
            </section>

            <section className={styles.section}>
                <h2>5. Changes to Disclaimer</h2>
                <p>
                    We reserve the right to amend this Disclaimer at any time. Updates will be reflected on this page,
                    and continued use of the Website implies acceptance of the revised terms.
                </p>
            </section>

            <section className={styles.section}>
                <h2>6. Contact Information</h2>
                <p>If you have questions or concerns, contact us at:</p>
                <p>
                    <strong>JOMS Commerce & Technologies Private Limited</strong><br />
                    No-592/2/635, NGEF Layout, Nagarbhavi,<br />
                    Bangalore North, Bangalore-560072, Karnataka, India<br />
                    Email: <a href="mailto:hello@joms.in" className={styles.link}>hello@joms.in</a>
                </p>
            </section>
        </div>
    );
};

export default Disclaimer;