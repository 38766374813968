import React from 'react';
import styles from '../../styles/components/careers/TeamsAtJoms.module.css';

const TeamsAtJoms = () => {
  const teams = [
    {
      icon: '/assets/images/careers/teams_1.png',
      title: 'Technology & Engineering',
      description: 'Engineering, Product Analytics & UI / UX',
    },
    {
      icon: '/assets/images/careers/teams_2.png',
      title: 'Operations',
      description: 'Project Management, Customer Experience and Support',
    },
    {
      icon: '/assets/images/careers/teams_3.png',
      title: 'Strategy & Marketing',
      description: 'Branding, Digital Marketing, Growth Strategies',
    },
    {
      icon: '/assets/images/careers/teams_4.png',
      title: 'Corporate Functions',
      description: 'Human Resources, Finance and Legal',
    },
  ];

  return (
    <section className={styles.container}>
      <h2 className={styles.heading}>Teams at JOMS</h2>
      <div className={styles.teamsContainer}>
        {teams.map((team, index) => (
          <div key={index} className={styles.teamCard}>
            <div className={styles.icon}>
              <img src={team.icon} alt={team.title} />
            </div>
            <h3 className={styles.title}>{team.title}</h3>
            <p className={styles.description}>{team.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TeamsAtJoms;
