import React from "react";
import styles from "../../styles/components/home/CoreValues.module.css";

const CoreValues = () => {
  const values = [
    {
      name: "Courage",
      icon: "/assets/images/core-values/courage.png",
      description: "Transcending limitations and redefining possibilities with bold innovation.",
    },
    {
      name: "Resilience",
      icon: "/assets/images/core-values/resilience.png",
      description: "Pushing forward through every challenge with strength and determination.",
    },
    {
      name: "Integrity",
      icon: "/assets/images/core-values/integrity.png",
      description: "Upholding transparency, honesty, and ethics in all that we pursue.",
    },
    {
      name: "Social Responsibility",
      icon: "/assets/images/core-values/empathy.png",
      description: "Harnessing innovation to create meaningful and lasting social impact.",
    },
    {
      name: "Passion",
      icon: "/assets/images/core-values/passion.png",
      description: "Bringing unwavering enthusiasm and commitment to everything we do.",
    },
  ];  

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Core Values</h2>
      <div className={styles.grid}>
        {values.map((value, index) => (
          <div className={styles.cardWrapper} key={index}>
            <div className={styles.card}>
              <img src={value.icon} className={styles.icon} alt={value.name} />
            </div>
            <p className={styles.text}>{value.name}</p>
            {/* <p className={styles.description}>{value.description}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoreValues;
