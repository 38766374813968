import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';
import styles from '../styles/pages/JobDetails.module.css';
import { FaRegCalendarAlt, FaSuitcase } from 'react-icons/fa';
import { FiMapPin } from 'react-icons/fi';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const JobDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      console.log("Success state changed to:", isSuccess);

      const toastTimeout = setTimeout(() => {
        toast.success('Application submitted successfully!');
      }, 1000);

      const resetTimeout = setTimeout(() => {
        setIsSuccess(false);
      }, 4000);

      return () => {
        clearTimeout(toastTimeout);
        clearTimeout(resetTimeout);
      };
    }
  }, [isSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { state } = location || {};
  const job = state?.job || {
    id: 'unknown',
    title: 'Unknown Job',
    details: {
      tags: ['N/A'],
      location: 'N/A',
      jobType: 'N/A',
      salary: 'N/A',
    },
    description: {
      overview: {
        jobTitle: 'N/A',
        mode: 'N/A',
        employmentType: 'N/A',
        duration: 'N/A',
      },
      aboutUs: 'No information available.',
      roleOverview: 'No information available.',
      keyResponsibilities: ['No responsibilities listed.'],
      qualifications: ['No qualifications listed.'],
      requirements: ['No requirements listed.'],
      location: 'N/A',
      perksAndBenefits: ['No perks listed.'],
      howToApply: 'No application instructions available.',
      gformurl: ''
    }
  };

  return (
    <>
      <ToastContainer style={{ zIndex: 3000 }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      
      <div className={styles.container}>
        <button className={styles.backButton} onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack /> <span>Back</span>
        </button>

        <div className={styles.cover}></div>

        <div className={styles.logoContainer}>
          <div className={styles.logo}></div>
          <h3 className={styles.logoText}>JOMS</h3>
        </div>

        <div className={styles.detailsContainer}>
          <div className={styles.header}>
            <h2 className={styles.title}>{job.title}</h2>
            <div className={styles.details}>
              <span className={styles.detailItem}><FaSuitcase /> {job.details?.type}</span>
              <span className={styles.detailItem}><FiMapPin /> {job.details?.mode}</span>
              {job.jobType === 'Full Time' && <span className={styles.detailItem}><FaRegCalendarAlt /> {job.details?.duration}</span>}
            </div>
          </div>
          <div className={styles.applyButtonContainer}>
            <button className={styles.applyButton} onClick={() => window.open(job?.gformurl, '_blank')}>
              Apply Now
            </button>
          </div>
        </div>

        <div className={styles.description}>
          <h3>About Us</h3>
          <p>{job.description.aboutUs}</p>

          <h3>Role Overview</h3>
          <p>{job.description.roleOverview}</p>

          {job.description?.keyResponsibilities?.length > 0 && (
            <>
              <h3>Key Responsibilities</h3>
              <ul>
                {job.description.keyResponsibilities.map((responsibility, index) => (
                  <li key={index}>{responsibility}</li>
                ))}
              </ul>
            </>
          )}

          {job.description?.qualifications?.length > 0 && (
            <>
              <h3>Qualifications</h3>
              <ul>
                {job.description.qualifications.map((qualification, index) => (
                  <li key={index}>{qualification}</li>
                ))}
              </ul>
            </>
          )}

          {job.description?.requirements?.length > 0 && (
            <>
              <h3>Requirements</h3>
              <ul>
                {job.description.requirements.map((requirement, index) => (
                  <li key={index}>{requirement}</li>
                ))}
              </ul>
            </>
          )}

          {job.description?.location && job.description.location !== 'N/A' && (
            <>
              <h3>Location</h3>
              <p>{job.description.location}</p>
            </>
          )}

          {job.description?.perksAndBenefits?.length > 0 && (
            <>
              <h3>Perks and Benefits</h3>
              <ul>
                {job.description.perksAndBenefits.map((perk, index) => (
                  <li key={index}>{perk}</li>
                ))}
              </ul>
            </>
          )}

          <h3>How to Apply</h3>
          <p>{job.description.howToApply}
            {job.jobType === 'Internship' && <span className={styles.mail}> hello@joms.in</span>}
          </p>
        </div>
      </div>
    </>
  );
};

export default JobDetails;
