import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Preloader from "./components/home/Preloader";
import Home from "./pages/Home";
import Openings from "./pages/Openings";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Disclaimer from "./pages/Disclaimer";
import CookiePolicy from "./pages/CookiePolicy";
import JobDetails from "./pages/JobDetails";
import JoinUs from "./pages/JoinUs";
import StoryDetails from "./pages/StoryDetails";
// import ProductDetails from "./pages/ProductDetails";
import Careers from "./pages/Careers";
import Internship from "./pages/Internship";
// import AdminLogin from "./admin/auth/AdminLogin";
// import AdminRegister from "./admin/auth/AdminRegister";
import { ToastContainer } from "react-toastify";
// import Dashboard from "./admin/main/Dashboard";
import useGoogleAnalytics from "./utils/useGoogleAnalytics";

const App = () => {
  const [loading, setLoading] = useState(true);
  useGoogleAnalytics("G-Q8S1V5S1TM");
  useEffect(() => {
    const loadData = () => {
      setTimeout(() => setLoading(false), 500);
    };

    loadData();
  }, []);

  // useEffect(() => {
  //   const pointerDot = document.querySelector(`.${styles.pointerDot}`);

  //   const handleMouseMove = (e) => {
  //     const x = e.clientX;
  //     const y = e.clientY;
  //     pointerDot.style.left = `${x}px`;
  //     pointerDot.style.top = `${y}px`;
  //   };

  //   document.addEventListener('mousemove', handleMouseMove);

  //   return () => {
  //     document.removeEventListener('mousemove', handleMouseMove);
  //   };
  // }, []);

  return (
    <Router>
      <div>
        {loading ? (
          <Preloader />
        ) : (
          <>
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/join-us" element={<JoinUs />} />
              <Route path="/joms-story" element={<StoryDetails />} />
              {/* <Route path="/joms-products" element={<ProductDetails />} /> */}
              <Route path="/careers/openings" element={<Openings />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/termsofservice" element={<TermsOfService />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/cookiepolicy" element={<CookiePolicy />} />
              <Route path="/careers/openings/job-details/:id" element={<JobDetails />} />
              <Route path="/careers/internship-at-joms" element={<Internship />} />
              {/* <Route path="/justonemorestep/admin/login" element={<AdminLogin toast={toast} />} />
              <Route path="/justonemorestep/admin/register" element={<AdminRegister toast={toast} />} />
              <Route path="/justonemorestep/dashboard" element={<Dashboard />} /> */}
            </Routes>
          </>
        )}
        {/* <div className={styles.pointerDot}></div> */}
      </div>
    </Router>
  );
};

export default App;
