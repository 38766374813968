// frontend/JoinUs.js
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../styles/pages/JoinUs.module.css";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
console.log("API_BASE_URL:", API_BASE_URL);
const countryCodes = [
  { code: "+91", country: "IN" },
  { code: "+1", country: "US" },
  { code: "+44", country: "UK" },
  { code: "+61", country: "AU" },
  { code: "+33", country: "FR" },
  { code: "+49", country: "DE" },
  { code: "+81", country: "JP" },
];

const JoinUs = () => {
  const [selectedOption, setSelectedOption] = useState("partner");
  const [formData, setFormData] = useState({
    name: "",
    phoneCode: "+91",
    phoneNumber: 0,
    email: "",
    companyName: "",
    location: "",
    website: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const handleOptionChange = (option) => setSelectedOption(option);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "phoneNumber" ? Number(value) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/join-us/join`, {
        ...formData,
        type: selectedOption,
      });
      console.log("Response:", response.data);
      if (response.data.success) {
        toast.success("Form submitted successfully!");
        setFormData({
          name: "",
          phoneCode: "+91",
          phoneNumber: 0,
          email: "",
          companyName: "",
          location: "",
          website: "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className={styles.heroSection}>
        <button className={styles.backButton} onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack /> <span>Back</span>
        </button>
        <h1 className={styles.heroTitle}>Let's Build Together</h1>
      </div>

      <div className={styles.contentSection}>
        <p className={styles.heroDescription}>
          Join a team where passion meets purpose, and every day feels like home. At JOMS, we foster a safe, vibrant space for exploration, innovation, and growth—together, we're always learning, doing, and becoming more.
        </p>

        <div className={styles.optionsSection}>
          {["partner", "investor"].map((option) => (
            <div
              key={option}
              className={`${styles.optionCard} ${selectedOption === option ? styles.active : ""}`}
              onClick={() => handleOptionChange(option)}
            >
              <div className={styles.radioWrapper}>
                <input
                  type="radio"
                  name="option"
                  value={option}
                  checked={selectedOption === option}
                  onChange={() => handleOptionChange(option)}
                  className={styles.radioInput}
                />
              </div>
              <div className={styles.optionContent}>
                <h2>{`Become ${option === "partner" ? "A Partner" : "An Investor"}`}</h2>
                <p>
                  {option === "partner"
                    ? "Let’s Break Barriers and Co-Create the Extraordinary. Collaborate with us to amplify your impact and unlock limitless possibilities. Let’s redefine success together."
                    : "Invest in Transformation, Create Value. Join JOMS on a groundbreaking journey where your contributions shape the future. It’s about more than capital, it’s the impact we create together that truly defines success."}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.formWrapper}>
        <form className={`${styles.formSection} ${styles.activeForm}`} onSubmit={handleSubmit}>
          <div className={styles.formContainer}>
            <div className={styles.formColumn}>
              <div className={styles.inputGroup}>
                <label htmlFor="name">Name*</label>
                <input
                  id="name"
                  name="name"
                  className={styles.input}
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter Your Full Name"
                  required
                />
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="phone">Phone*</label>
                <div className={styles.phoneContainer}>
                  <select
                    name="phoneCode"
                    className={styles.countryCodeSelect}
                    value={formData.phoneCode}
                    onChange={handleChange}
                  >
                    {countryCodes.map(({ code, country }) => (
                      <option key={code} value={code}>
                        {code} ({country})
                      </option>
                    ))}
                  </select>
                  <input
                    id="phoneNumber"
                    name="phoneNumber"
                    className={styles.input}
                    type="number"
                    placeholder="Enter your phone"
                    value={formData.phoneNumber === 0 ? "" : formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="email">E-mail*</label>
                <input
                  id="email"
                  name="email"
                  className={styles.input}
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter Email"
                  required
                />
              </div>
            </div>

            <div className={styles.centerLine}></div>

            <div className={styles.formColumn}>
              <div className={styles.inputGroup}>
                <label htmlFor="companyName">Company Name*</label>
                <input
                  id="companyName"
                  name="companyName"
                  className={styles.input}
                  type="text"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Enter Company Name"
                  required
                />
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="location">Location*</label>
                <input
                  id="location"
                  name="location"
                  className={styles.input}
                  type="text"
                  value={formData.location}
                  onChange={handleChange}
                  placeholder="Enter Location"
                  required
                />
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor="website">Company Website (Optional)</label>
                <input
                  id="website"
                  name="website"
                  className={styles.input}
                  type="url"
                  value={formData.website}
                  onChange={handleChange}
                  placeholder="https://"
                />
              </div>
            </div>
          </div>
          <button
            className={`${styles.submitButton}`}
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default JoinUs;