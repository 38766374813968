import React from "react";
import styles from "../../styles/components/home/Contact.module.css";
import { FaLinkedinIn, FaEnvelope } from "react-icons/fa";

const Contact = () => {
    return (
        <div className={styles.contactContainer}>
            {/* Left Section - Contact Details */}
            <div className={styles.contactDetails}>
                <h2 className={styles.heading}>Contact</h2>
                <p className={styles.email}>
                    <strong>Email:</strong> hello@joms.in
                </p>
                <p className={styles.address}>
                    <strong>Office Address:</strong><br />
                    #592/2/635, NGEF Layout, Nagarbhavi,
                    Bangalore North, Bangalore-560072.
                </p>
                <div className={styles.social}>
                    <a
                        href="https://linkedin.com/company/justonemorestep"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.linkedinIcon}
                    >
                        <FaLinkedinIn />
                    </a>
                    <a href="mailto:hello@joms.in" className={styles.linkedinIcon} >
                        <FaEnvelope />
                    </a>
                </div>
            </div>

            {/* Divider Line */}
            <div className={styles.divider}></div>

            {/* Right Section - Google Map */}
            <div className={styles.mapSection}>
                <h2 className={styles.heading}>Headquarters</h2>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.171681694052!2d77.5094518!3d12.960863699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x26aa7f79d8962127%3A0xee85838b565884fe!2sJOMS%20Commerce%20%26%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1738312786274!5m2!1sen!2sin"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="address"
                // width={400}
                // height={300}
                ></iframe>
                {/* <iframe title="address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.171681694052!2d77.5094518!3d12.960863699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x26aa7f79d8962127%3A0xee85838b565884fe!2sJOMS%20Commerce%20%26%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1738312786274!5m2!1sen!2sin" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            </div>
        </div>
    );
};

export default Contact;
