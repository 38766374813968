import React from 'react'
import styles from '../../styles/components/internship/Qualification.module.css';

const Qualification = () => {
    return (
        <div className={styles.container}>
            <h2 className={styles.heading}>Qualification Requirements</h2>
            <div className={styles.contentWrapper}>
                <div className={styles.listContainer}>
                    <div className={styles.item}>
                        <span className={styles.number}>1</span>
                        <p className={styles.text}>
                            Students must be enrolled in any of the following academic programs: Computer Science, Electronics and Communication, Information Science, Design, Business Management, Finance, Marketing, Economics, and Creative Arts.
                        </p>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.number}>2</span>
                        <p className={styles.text}>
                            Students must be in their 3rd or 4th year of undergraduate studies or enrolled in any year of a postgraduate program at the time of application.
                        </p>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.number}>3</span>
                        <p className={styles.text}>
                            Students must have a minimum cumulative grade point average (CGPA) of 7 or 70% up to the latest semester.
                        </p>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.number}>4</span>
                        <div className={styles.subListContainer}>
                            <p className={styles.text}>
                                Students should have:
                            </p>
                            <p className={styles.text}>Technical Skills: Proficiency in Python, Java, React, etc.</p>
                            <p className={styles.text}>Soft Skills: Strong communication, problem-solving, teamwork, and integrity.</p>
                            <p className={styles.text}>Preference: Certifications, relevant projects, or prior experience aligned with JOMS' industry are a plus.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.imageContainer}>
                    <img src="/assets/images/internship/qualification.avif" alt="Qualification" className={styles.image} />
                </div>
            </div>
        </div>
    );
};


export default Qualification