import React from 'react';
import styles from "../styles/pages/PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>JOMS Commerce & Technologies Private Limited – Privacy Policy</h1>
        <p>Last Updated On: 28th January 2025</p>
      </header>

      <section className={styles.section}>
        <p>
          JOMS Commerce & Technologies Private Limited ("Company," "we," "our," or "us") values
          your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your
          personal information when you visit our website, interact with our services, or engage with us.
          By using our website or services, you agree to the practices described in this Privacy Policy. If
          you do not agree, please refrain from using our services.
        </p>
      </section>

      <section className={styles.section}>
        <h2>1. Information We Collect:</h2>
        <p>We collect information that you provide to us directly and automatically when you use our
          website or services. This includes:</p>
        <ul>
          <li><strong>Personal Information:</strong>
            <ul>
              <li>Name, email address, phone number, and address.</li>
              <li>Login credentials, including username and password.</li>
            </ul>
          </li>
          <li><strong>Non-Personal Information:</strong>
            <ul>
              <li>Browser type, operating system, IP address, and device identifiers.</li>
              <li>Usage data, such as pages viewed, time spent on the website, and interactions with
                content.</li>
            </ul>
          </li>
          <li><strong>Information from Third Parties:</strong>
            <ul>
              <li>Data from analytics tools or advertising partners.</li>
              <li>Information shared via social media platforms if you log in using those services.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2>2. How We Use Your Information:</h2>
        <ul>
          <li><strong>To Provide Services:</strong>
            <ul>
              <li>Manage user accounts.</li>
              <li>Provide customer support.</li>
            </ul>
          </li>
          <li><strong>To Improve Our Website and Services:</strong>
            <ul>
              <li>Analyze website usage and user behavior.</li>
              <li>Enhance functionality and user experience.</li>
            </ul>
          </li>
          <li><strong>For Marketing and Communications:</strong>
            <ul>
              <li>Send promotional emails, newsletters, and offers.</li>
              <li>Display personalized ads based on user preferences.</li>
            </ul>
          </li>
          <li><strong>To Comply with Legal Obligations:</strong>
            <ul>
              <li>Meet requirements under applicable laws, such as tax or consumer protection regulations.</li>
              <li>Address fraud prevention, security, and enforcement.</li>
            </ul>
          </li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2>3. Sharing Your Information</h2>
        <p>We may share your information with:</p>
        <ul>
          <li><strong>Service Providers:</strong> Third-party vendors who assist with marketing or analytics.</li>
          <li><strong>Business Partners:</strong> Companies with whom we collaborate to offer joint products or
            services.</li>
          <li><strong>Legal Authorities:</strong> When required to comply with legal obligations, subpoenas, or court
            orders.</li>
          <li><strong>Merger or Acquisition:</strong> In the event of a merger, acquisition, or sale of company assets,
            your information may be transferred.</li>
        </ul>
        <p>We will never sell your personal information to any other parties that is not listed in this
          agreement without your consent.</p>
      </section>

      <section className={styles.section}>
        <h2>4. Cookies and Tracking Technologies</h2>
        <p>We use cookies, web beacons, and similar technologies to collect information about your
          browsing behavior. For more details, please refer to our <a href="/cookiepolicy" className={styles.link}>Cookie Policy</a>.</p>
      </section>

      <section className={styles.section}>
        <h2>5. User Rights</h2>
        <p>Depending on your location, you may have the following rights regarding your personal data:</p>
        <ul>
          <li><strong>Access:</strong> Request a copy of the personal data we hold about you.</li>
          <li><strong>Correction:</strong> Update inaccurate or incomplete information.</li>
          <li><strong>Deletion:</strong>  Request the deletion of your personal information, subject to legal or
            contractual obligations.</li>
          <li><strong>Portability:</strong> Request a copy of your data in a portable format.</li>
          <li><strong>Restriction:</strong> Restrict the processing of your personal information under certain
            conditions.</li>
          <li><strong>Objection:</strong> Object to the processing of your personal data for direct marketing or other
            purposes.</li>
          <li><strong>Withdraw Consent:</strong> Revoke any consent previously given for data processing.</li>
        </ul>
        <p>To exercise these rights, please contact us at <a href="mailto:hello@joms.in" className={styles.link}>hello@joms.in</a>.</p>
      </section>

      <section className={styles.section}>
        <h2>6. Data Security</h2>
        <p>We implement appropriate technical and organizational measures to safeguard your information
          from unauthorized access, disclosure, alteration, or destruction. However, no security system is
          impenetrable, and we cannot guarantee absolute security.</p>
      </section>

      <section className={styles.section}>
        <h2>7. Data Retention</h2>
        <p>We retain your personal information only for as long as necessary to fulfill the purposes outlined
          in this <a href='/privacypolicy' className={styles.link}>Privacy Policy</a> or as required by law. Once retention periods expire, your data will be
          securely deleted or anonymized.</p>
      </section>

      <section className={styles.section}>
        <h2>8. International Data Transfers</h2>
        <p>If you are accessing our services from outside India, your information may be transferred to and
          processed in India or other countries where our service providers operate. By using our services,
          you consent to such data transfers.</p>
      </section>

      <section className={styles.section}>
        <h2>9. Third-Party Links</h2>
        <p>Our website may contain links to third-party websites or services. We are not responsible for the
          privacy practices or content of these external sites. We encourage you to review their privacy
          policies before providing personal information.</p>
      </section>

      <section className={styles.section}>
        <h2>10. Children’s Privacy</h2>
        <p>Our products or services are not directed at children under 18 years of age. We do not knowingly
          collect personal information from children. If we discover that we have inadvertently collected
          such data, we will promptly delete it.</p>
      </section>

      <section className={styles.section}>
        <h2>11. Updates to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices, legal
          requirements, or other circumstances. Updates will be posted on this page with a revised
          "Effective Date.” Your continued use of our services constitutes acceptance of the updated
          policy.</p>
      </section>

      <section className={styles.section}>
        <h2>11. Contact Information</h2>
        <p>If you have questions, concerns, or requests related to this <a href='/privacypolicy' className={styles.link}>Privacy Policy</a>, please contact us at:</p>
        <p>
          JOMS Commerce & Technologies Private Limited<br />
          No-592/2/635, NGEF Layout, Nagarbhavi, Bangalore North,<br />
          Bangalore-560072, Karnataka, India<br />
          Email: <a href="mailto:hello@joms.in" className={styles.link}>hello@joms.in</a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
