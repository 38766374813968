import React from "react";
import styles from "../../styles/components/home/AboutUs.module.css";

const AboutUs = () => {
  return (
    <div className={styles.container} id="aboutus">
      <section className={styles.aboutSection}>
        <h2 className={styles.heading}>About Us</h2>
        {/* <p className={styles.subheading}>
          JOMS, short for <strong className={styles.highlight}>Just One More Step</strong>, combines creativity and technology to develop innovative products that elevate customer experiences.
        </p> */}

        <div className={styles.contentContainer}>
          {/* Text Content */}
          <div className={styles.textContent}>
            <p className={styles.description}>
            <span className={styles.highlight} >Just One More Step (JOMS)</span> is a technology company that blends innovation and creativity to develop premium digital products that enhance customer experiences, redefine interactions, and create lasting value. We are developing a next-generation personalised mobile application that seamlessly connects people beyond borders, streamlining the process of planning, discovering venues and organising meetups or events, saving valuable time to enhance social experience.
            </p>

            {/* Vision & Mission Section */}
            <div className={styles.cardContainer}>
              <div className={styles.card}>
                <h3 className={styles.cardHeading}>Vision</h3>
                <p className={styles.cardDescription}>
                  Inspiring global change by innovating products and services that uplift humanity and create a brighter future.
                </p>
              </div>

              <div className={styles.card}>
                <h3 className={styles.cardHeading}>Mission</h3>
                <p className={styles.cardDescription}>
                  Enhancing experiences through passion, creativity, and technology to drive meaningful innovation and growth.
                </p>
              </div>
            </div>
          </div>

          {/* Image Section */}
          {/* <div className={styles.imageContainer}>
            <img
              src="/assets/images/home/aboutus.webp"
              alt="About Us"
              className={styles.image}
            />
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default AboutUs;