import React from "react";
import styles from "../../styles/components/careers/Hero.module.css";
import { useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";

const Hero = () => {
  const navigate = useNavigate();
  const handleCheckOpenings = () => {
    navigate("/careers");
    setTimeout(() => {
      const section = document.getElementById("current-openings");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  return (
    <section className={styles.hero}>

      <div className={styles.overlay}>
      <div className={styles.backButton} onClick={() => navigate('/')}>
        <IoIosArrowRoundBack /> <span>Home</span>
      </div>
        <div className={styles.content}>
          <h1 className={styles.heading}>
            We're Made Of People <br/> Who Can Make A <span className={styles.highlight}>Difference</span>
          </h1>
          <button className={styles.button} onClick={handleCheckOpenings}>Check Openings</button>
        </div>
      </div>
    </section>
  );
};

export default Hero;