import React from 'react';
import { FaGlobe, FaUsers, FaLightbulb } from 'react-icons/fa';
import styles from '../../styles/components/home/Roadmap.module.css';

const RoadMap = () => {
  return (
    <section className={styles.container}>
      <h2 className={styles.heading}>Roadmap</h2>
      <p className={styles.subheading}>
        At JOMS, we aspire to be a trailblazer in consumer innovation, celebrated for driving meaningful change, and constantly evolving to meet the needs of tomorrow.
      </p>
      <div className={styles.roadmapContainer}>
        <div className={styles.roadmapItem}>
          <FaLightbulb className={styles.roadmapIcon} />
          <div className={styles.roadmapContent}>
            <h3 className={styles.roadmapSubtitle}>Innovation</h3>
            <p className={styles.roadmapText}>Embracing cutting-edge technologies to elevate our offerings.</p>
          </div>
        </div>

        <div className={styles.roadmapItem}>
          <FaUsers className={styles.roadmapIcon} />
          <div className={styles.roadmapContent}>
            <h3 className={styles.roadmapSubtitle}>Community</h3>
            <p className={styles.roadmapText}>Collaborating with visionary partners to amplify social impact.</p>
          </div>
        </div>

        <div className={styles.roadmapItem}>
          <FaGlobe className={styles.roadmapIcon} />
          <div className={styles.roadmapContent}>
            <h3 className={styles.roadmapSubtitle}>Globalization</h3>
            <p className={styles.roadmapText}>Expanding our footprint into global markets.</p>
          </div>
        </div>

      </div>
    </section>
  );
};

export default RoadMap;

