import React from 'react';
import styles from '../../styles/components/home/Story.module.css';
import { useNavigate } from 'react-router-dom';

const Story = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Our Story</h2>
      <div className={styles.contentWrapper}>
        <div className={styles.textWrapper}>
          <p className={styles.description}>
            Discover the story behind <span className={styles.highlight}>JOMS</span>,<br/> a journey fueled by resilience,  creativity
            and a passion to break conventions.
          </p>
          <button className={styles.button} onClick={() => navigate('/joms-story')}>Learn More</button>
        </div>
        {/* <div className={styles.imageWrapper}> */}
          <img
            src="/assets/images/home/joms-story.png"
            alt="Tree Illustration"
            className={styles.image}
          />
        {/* </div> */}
      </div>
    </div>
  );
};

export default Story;