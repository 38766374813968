import React from 'react'
import styles from '../../styles/components/internship/Hero.module.css'
import { useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { FaChevronDown } from 'react-icons/fa';

const Hero = () => {
    const navigate = useNavigate();

    const scrollToAboutUs = () => {
        const aboutUsSection = document.getElementById('whyjoms');
        if (aboutUsSection) {
          aboutUsSection.scrollIntoView({ behavior: 'smooth' });
        }
      };
    return (
        <div className={styles.hero}>
            <div className={styles.contentWrapper}>
                <button className={styles.backButton} onClick={() => navigate(-1)}>
                    <IoIosArrowRoundBack /> <span>Back</span>
                </button>
                <div className={styles.textContent}>
                    <h1 className={styles.heading}>Intern At</h1>
                    <h1 className={styles.heading}>JOMS</h1>
                    <button className={styles.button} onClick={() => navigate('/careers/openings')}>Explore Internships</button>
                </div>
            </div>

            <div className={styles.scrollIcon} onClick={scrollToAboutUs}>
                <div className={styles.iconText}>Scroll</div>
                <FaChevronDown color="#fff" />
            </div>
        </div>
    );
};

export default Hero;
