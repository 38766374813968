import React from "react";
import styles from "../../styles/components/careers/AboutUs.module.css";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  const handleCheckOpenings = () => {
    navigate("/careers");
    setTimeout(() => {
      const section = document.getElementById("job-subscription");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>About Us</h2>
      <div className={styles.content}>
        <div className={styles.textSection}>
          <p className={styles.description}>
            <strong>JOMS</strong> short for <strong>Just One More Step</strong> combines
            creativity and technology to develop innovative products that elevate
            customer experiences. Our flagship projects include a range of
            cutting-edge products and solutions, such as e-commerce marketplace,
            premium fashion brands, curated luxury experiences, and much more.
          </p>
          <button className={styles.button} onClick={handleCheckOpenings}>Get in touch</button>
        </div>
        <div className={styles.imageSection}>
          <img
            src="/assets/images/careers/aboutus.jpeg"
            alt="Hands painted red forming a heart"
            className={styles.image}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
